<template>
  <div class="comingsoon-page">
    <div class="logoOuter">
      <img src="logo.png" alt="" />
      <p>
        Our Web Application is under construction. We`ll be here soon<br />with
        our new awesome Web Application.
      </p>
    </div>
    <svg width="500px" height="200px" viewBox="0 0 200 200">
      <text
        fill="none"
        stroke="#fff"
        stroke-width="5"
        font-size="50"
        font-weight="500"
        class="is-active"
      >
        Coming Soon!
      </text>
    </svg>

    <!-- Chat Icon -->
    <div class="chat-icon" @click="toggleChat">
      💬
    </div>

    <!-- Chat Popup -->
    <div v-if="isChatOpen" class="chat-popup">
      <div class="chat-header">
        <span>Chat with Us</span>
        <button @click="toggleChat">✖</button>
      </div>
      <div class="chat-body" id="chat_room">
        <ul>
          <li v-for="(message, index) in chatMessages" :key="index" :class="message.sender">
            <img v-if="message.sender === 'user'" src="assets/img/user.png" alt="User" />
            <img v-else src="assets/img/fav-icon.png" alt="Agent" />
            {{ message.text }}
          </li>
        </ul>
      </div>
      <div class="chat-footer">
        <input v-model="userMessage" @keydown.enter="sendMessage" placeholder="Type a message..." />
        <button @click="sendMessage">Send</button>
      </div>
    </div>
  </div>
</template>  
<script>
import axios from "axios";
export default {
  name: "HexaHome",
  props: {
    msg: String,
  },
  data() {
    return {
      isChatOpen: false,
      userMessage: '',
      chatMessages: [],
      labels:""
    };
  },
  watch: {
    caller_caption(newValue) {
      console.log("testttttttttttttttt", newValue);
      if (newValue != null) {
        // this.sendRASAText(newValue); // Trigger when caller_caption changes
      }
    },
  },
  created() {},
  mounted() {
   
  },
  computed: {
   
  },
  methods: {
     toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    sendMessage() {
      if (this.userMessage.trim() !== '') {
        // Add user message to chat
        this.chatMessages.push({ text: this.userMessage, sender: 'user' });

        this.sendRASAText(this.userMessage);        
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatBody = document.getElementById("chat_room");
        chatBody.scrollTop = chatBody.scrollHeight;
      });
    },
    async sendRASAText(text) {
      if (text != null) {
        const res = await axios.post(
          "https://hexadegree.com:5005/webhooks/rest/webhook?token=this2is3mysecret7",
          {
            sender: "user123",
            message: text,
          }
        );
        if (res.status == 200) {
          if (res.data.length != 0) {
            console.log(res.data);
            this.labels = res.data[0].text;
          } else {
            // this.labels = [{ text: "Can You please Explain in detail?" }];
            this.labels = "Can You please Explain in detail?";

            console.log(res.data);
          }

          // Simulate an agent reply after a short delay
            setTimeout(() => {
              this.chatMessages.push({ text: this.labels, sender: 'agent' });
              this.scrollToBottom();
            }, 1000);

            // Clear the input field
            this.userMessage = '';
            this.scrollToBottom();
        }
      }
    },

    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
body,
text,
p {
  font-family: "Poppins", sans-serif;
} */

/* Coming Soon Pgae */
/* .comingsoon-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: url(coming-soon-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  flex-direction: column;
}

.comingsoon-page:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1c2f4dc2;
  z-index: -1;
} */

/* .is-active {
  stroke-width: 5px;
  stroke-dasharray: 900;
  stroke-dashoffset: -900;
  animation: text 4s forwards 1;
  animation-delay: 1s;
  transform: translate(-60%, 50%);
  font-size: 60px;
}

.logoOuter {
  text-align: center;
  color: #fff;
  font-size: 20px;
  letter-spacing: 0.5px;
  animation: hide 4s forwards 1;
  animation-delay: 1s;
  position: relative;
  overflow: hidden;
}

.logoOuter img {
  position: relative;
  left: 1000px;
  width: 400px;
  filter: drop-shadow(-1000px 0px 0px #fff);
  margin-bottom: 30px;
}

@keyframes text {
  75% {
    fill: transparent;
    stroke-dashoffset: 0;
  }

  100% {
    fill: #fff;
    stroke-dashoffset: 0;
    stroke-width: 0;
  }
}

@keyframes hide {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

/* Chat Icon */
.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

/* Chat Popup */
.chat-popup {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 300px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.chat-header {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-body {
  padding: 10px;
  height: 200px;
  overflow-y: auto;
  background-color: #f1f1f1;
}

.chat-body ul {
  list-style-type: none;
}

.chat-body li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chat-body li.user {
  justify-content: flex-end;
}

.chat-body li.user img {
  order: 2;
  margin-left: 10px;
}

.chat-body li.agent {
  justify-content: flex-start;
}

.chat-body li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.chat-footer input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-footer button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
